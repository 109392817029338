import logo from './logo.svg';
import './App.css';
import { useState, useEffect, useRef } from "react";
import { NumericFormat } from 'react-number-format';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from "react-chartjs-2";
import { Layout, Menu, Breadcrumb, Input, Select, Button, Form, InputNumber } from 'antd';
import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from '@aws-amplify/auth';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { ThemeProvider } from '@aws-amplify/ui-react';
import { DashboardOutlined, PieChartOutlined, SettingOutlined, LogoutOutlined, PlusOutlined } from '@ant-design/icons';
import { signOut } from '@aws-amplify/auth';
import { useMediaQuery } from 'react-responsive';
// import awsExports from './aws-exports';

const awsExports = {
  aws_project_region: 'us-east-1',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_9ylEiu1FZ',
  aws_user_pools_web_client_id: 'ud0jliv2lknblfq3baf4oasq9',
  oauth: {
    domain: 'https://zaffalon-financial.auth.us-east-1.amazoncognito.com',
    scope: ['email', 'openid', 'profile'],
    redirectSignIn: 'http://localhost:3000/',
    redirectSignOut: 'http://localhost:3000/',
    responseType: 'code' // or 'token', use 'code' for Authorization code grant
  }
};

Amplify.configure(awsExports);

const { Header, Content, Footer } = Layout;
const { Option } = Select;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const theme = {
  name: 'custom-theme',
  tokens: {
    colors: {
      brand: {
        primary: {
          10: '#f5f8ff',
          20: '#e5ecff',
          40: '#d1dcff',
          60: '#b8c7ff',
          80: '#8ea3ff',
          90: '#6478ff',
          100: '#4318FF',
        },
      },
    },
    components: {
      authenticator: {
        router: {
          borderWidth: '0px',
        },
        container: {
          boxShadow: '{shadows.large}',
          borderRadius: '{radii.large}',
          padding: '{space.xl}',
          backgroundColor: '{colors.background.primary}',
        },
        form: {
          padding: '{space.xl}',
        },
        footer: {
          paddingTop: '{space.xl}',
          paddingBottom: '{space.xl}',
        },
      },
    },
  },
};

function App() {

  const [assets, setAssets] = useState()
  //  => {
  //   fetchDataFromAPI();
  //   const savedValue = localStorage.getItem("assets");
  //   return savedValue !== null ? JSON.parse(savedValue) : [
  //     { name: "Nomad", type: "USD", value: 0 },
  //     { name: "BS2 PJ", type: "USD", value: 0 },
  //     { name: "Inter Invest", type: "BRL", value: 0 },
  //     { name: "Inter", type: "BRL", value: 0 },
  //     { name: "Nubank", type: "BRL", value: 0 },
  //     { name: "T-Cross", type: "asset", value: 0 },
  //     // add more assets here
  //   ];
  // });

  const [netWorthData, setNetWorthData] = useState()
  // () => {
  //   const savedValue = localStorage.getItem("netWorthData");
  //   return savedValue !== null ? JSON.parse(savedValue) : [];
  // });
  const [newAsset, setNewAsset] = useState({ name: "", type: "", value: 0 });
  const [usdbrl, setUsdbrl] = useState(null);
  const [totalDollar, setTotalDollar] = useState(0);
  const [totalReal, setTotalReal] = useState(0);
  const [totalAssets, setTotalAssets] = useState(0);
  const [netWorth, setNetWorth] = useState(0);
  const [updateTime, setUpdateTime] = useState(null);
  const [lineData, setLineData] = useState({
    labels: [""],
    datasets: [
      {
        label: "Net Worth",
        data: [1],
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1
      }
    ]
  });
  const [session, setSession] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    let timeoutId = setTimeout(() => {
    fetch("https://economia.awesomeapi.com.br/json/last/USD-BRL")
      .then((response) => response.json())
      .then((data) => {
        const usdbrlValue = data.USDBRL.ask;
        setUsdbrl(usdbrlValue);
      })
      .catch((error) => {
        console.error("Failed to fetch USD-BRL exchange rate:", error);
      });
      fetchDataFromAPI();
    }, 500);
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      localStorage.setItem("assets", JSON.stringify(assets));
      // saveDataToAPI(assets, netWorthData);
      handleUpdate();
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [assets]);


  useEffect(() => {
    let timeoutId = setTimeout(() => {
      handleUpdate();
      saveDayNetWorth(netWorth);
      saveDataToAPI(assets, netWorthData);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [usdbrl, netWorth]);


  useEffect(() => {
    if (!netWorthData || netWorthData.length === 0) {
      return;
    }

    const chartData = {
      labels: netWorthData.map((x) => x.updateTime),
      datasets: [
        {
          label: "Net Worth",
          data: netWorthData.map((x) => x.netWorth),
          fill: false,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1
        },
        {
          label: "Total Real",
          data: netWorthData.map((x) => x.totalReal),
          fill: false,
          borderColor: "rgb(75, 193, 122)",
          tension: 0.1
        },
        {
          label: "Total Dollar",
          data: netWorthData.map((x) => x.totalDollar),
          fill: false,
          borderColor: "rgb(75, 12, 192)",
          tension: 0.1
        }
      ]
    };

    setLineData(chartData);
  }, [netWorthData]);


  // save net worth and update time to local storage when net worth changes
  const saveDayNetWorth = (newNetWorth) => {
    const date = new Date();
    const formattedDate = date.toISOString().split('T')[0];
    setUpdateTime(formattedDate);

    if (netWorth !== 0) {
      let savedData = [...(netWorthData || [])]; // Create a new array to trigger state update
      const data = {
        netWorth: newNetWorth,
        totalReal,
        totalDollar: totalDollar * usdbrl,
        updateTime: formattedDate
      };

      // Find index of today's data
      const todayIndex = savedData.findIndex(x => x.updateTime === formattedDate);

      if (todayIndex !== -1) {
        // Update existing entry for today
        savedData[todayIndex] = data;
      } else {
        // Add new entry if no entry exists for today
        savedData.push(data);
      }

      // Sort data by date
      savedData.sort((a, b) => new Date(a.updateTime) - new Date(b.updateTime));

      localStorage.setItem("netWorthData", JSON.stringify(savedData));
      setNetWorthData([...savedData]); // Create new array reference to ensure state update

      // Update chart data immediately
      setLineData({
        labels: savedData.map((x) => x.updateTime),
        datasets: [
          {
            label: "Net Worth",
            data: savedData.map((x) => x.netWorth),
            fill: false,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1
          },
          {
            label: "Total Real",
            data: savedData.map((x) => x.totalReal),
            fill: false,
            borderColor: "rgb(75, 193, 122)",
            tension: 0.1
          },
          {
            label: "Total Dollar",
            data: savedData.map((x) => x.totalDollar),
            fill: false,
            borderColor: "rgb(75, 12, 192)",
            tension: 0.1
          }
        ]
      });
    }
  };

  const getAuthToken = async () => {
    const session = await fetchAuthSession();
    const idToken = session.tokens.idToken.toString()
    return idToken;
  };

  const handleUpdate = () => {
    if (assets == null)
      return

    const totalUSD = assets
      .filter((asset) => asset.type === "USD")
      .reduce((sum, asset) => sum + asset.value, 0);

    setTotalDollar(totalUSD);

    const totalBRL = assets
      .filter((asset) => asset.type === "BRL")
      .reduce((sum, asset) => sum + asset.value, 0);
    setTotalReal(totalBRL);

    const totalAssets = assets
      .filter((asset) => asset.type === "asset")
      .reduce((sum, asset) => sum + asset.value, 0);
    setTotalAssets(totalAssets);

    const newNetWorth = (totalUSD * usdbrl) + totalBRL + totalAssets;
    if (usdbrl) {
      setNetWorth(newNetWorth);
    }
  }

  const addNewAsset = () => {
    const newAssets = [...assets];
    newAssets.push(newAsset);
    setAssets(newAssets);
    setNewAsset({ name: newAsset.name, type: newAsset.type, value: 0 });
  }

  // handle input change
  const handleInputChange = (index, value) => {
    const newAssets = [...assets];
    newAssets[index].value = value;
    setAssets(newAssets);
  };

  const handleSelectChange = (value) => {
    setNewAsset({
      ...newAsset,
      ["type"]: value,
    });
  };

  const handleNameChange = (event) => {
    const { value } = event.target
    setNewAsset({
      ...newAsset,
      ["name"]: value,
    });
  };


  const handleDelete = (index) => {
    const newAssets = [...assets];
    newAssets.splice(index, 1);
    setAssets(newAssets);
  };

  const API_URL = 'https://9fpfpmay20.execute-api.us-east-1.amazonaws.com/Financial';

  const saveDataToAPI = async (assets, netWorthData) => {
    if (assets === undefined)
      return

    try {
      const idToken = await getAuthToken();
      const response = await fetch(`${API_URL}/financial-data`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Authorization': idToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          assets,
          netWorthData,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  // Function to fetch data
const fetchDataFromAPI = async () => {
  try {
    const token = await getAuthToken();
    const response = await fetch(`${API_URL}/financial-data`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      // Handle 404 (Not Found) separately if needed
      if (response.status === 404) {
        console.log('Data not found.');
        return;
      }
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    setAssets(data.assets ? data.assets.map((asset) => ({ name: asset.name, type: asset.type, value: parseFloat(asset.value)})) : []) ;
    setNetWorthData(data.netWorthData ? data.netWorthData : []);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

  const menuItems = [
    {
      key: '1',
      icon: <DashboardOutlined />,
      label: 'Dashboard',
    },
    {
      key: '2',
      icon: <PieChartOutlined />,
      label: 'Assets',
    },
    {
      key: '3',
      icon: <SettingOutlined />,
      label: 'Settings',
    },
  ];

  const handleSignOut = async () => {
    try {
      await signOut();
    } catch (error) {
      console.log('error signing out:', error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Layout style={{ minHeight: '100vh' }}>
        <Header style={{
          background: '#fff',
          padding: '0 20px',
          boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          height: 'auto',
          minHeight: '64px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: isMobile ? 'wrap' : 'nowrap',
        }}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
            flexWrap: isMobile ? 'wrap' : 'nowrap',
          }}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: isMobile ? 'wrap' : 'nowrap',
              width: isMobile ? '100%' : 'auto',
            }}>
              <h1 style={{
                margin: isMobile ? '10px 0' : '0 24px 0 0',
                fontSize: '20px',
                fontWeight: 600,
                color: '#4318FF',
                width: isMobile ? '100%' : 'auto',
                textAlign: isMobile ? 'center' : 'left',
              }}>
                My Financial App
              </h1>
              <Menu
                mode="horizontal"
                defaultSelectedKeys={['1']}
                items={menuItems}
                style={{
                  border: 'none',
                  flex: 1,
                  minWidth: isMobile ? '100%' : 360,
                }}
              />
            </div>
            <Button
              type="text"
              icon={<LogoutOutlined />}
              onClick={handleSignOut}
              style={{
                fontSize: '16px',
                color: '#666',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                margin: isMobile ? '10px 0' : 0,
                width: isMobile ? '100%' : 'auto',
                justifyContent: 'center',
              }}
            >
              Logout
            </Button>
          </div>
        </Header>
        <div style={{
          width: '100%',
          maxWidth: '2000px',
          margin: '0 auto',
          padding: isMobile ? '0 15px' : '0 32px',
        }}>
          <Content>
            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>Minhas finanças</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: "flex-start",
              gap: "32px",
              width: '100%',
            }}>
              <div style={{
                width: isMobile ? '100%' : '35%',
                maxWidth: isMobile ? 'none' : '600px',
                padding: isMobile ? '10px 0' : '0',
              }}>
                <div style={styles.section}>
                  {usdbrl ? (
                    <p style={styles.exchangeRate}>USD-BRL exchange rate: {usdbrl}</p>
                  ) : (
                    <p>Loading...</p>
                  )}
                  <h1 style={styles.sectionTitle}>Minhas finanças</h1>
                  <div style={styles.section}>
                    <h2 style={styles.subTitle}>Dólar</h2>
                    {assets?.map((asset, index) => {
                      if (asset.type === "USD") {
                        return (
                          <div style={styles.lineItem} key={index}>
                            <div style={styles.inputRow}>
                              <label style={styles.labelLine}>{asset.name}: {asset.type === "USD" ? "US$" : "R$"}</label>
                              <div style={styles.inputWithDelete}>
                                <InputNumber
                                  style={styles.input}
                                  value={asset.value}
                                  onChange={(event) => handleInputChange(index, event)}
                                  step="0.01"
                                  min="0"
                                  precision={2}
                                  decimalSeparator=","
                                />
                                <Button
                                  type="text"
                                  danger
                                  onClick={() => handleDelete(index)}
                                  style={styles.deleteButton}
                                >
                                  X
                                </Button>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                    <p style={styles.totalAmount}><b>Total em dólar: US$ {totalDollar.toLocaleString(navigator.language, { maximumFractionDigits: 2 })}</b></p>
                    <p style={styles.totalAmount}><b>Total em real: R$ {(totalDollar * usdbrl).toLocaleString(navigator.language, { maximumFractionDigits: 2 })}</b></p>
                  </div>

                  <div style={styles.section}>
                    <h2 style={styles.subTitle}>Real</h2>
                    {assets?.map((asset, index) => {
                      if (asset.type === "BRL") {
                        return (
                          <div style={styles.lineItem} key={index}>
                            <div style={styles.inputRow}>
                              <label style={styles.labelLine}>{asset.name}: {asset.type === "USD" ? "US$" : "R$"}</label>
                              <div style={styles.inputWithDelete}>
                                <InputNumber
                                  style={styles.input}
                                  value={asset.value}
                                  onChange={(event) => handleInputChange(index, event)}
                                  step="0.01"
                                  min="0"
                                  precision={2}
                                  decimalSeparator=","
                                />
                                <Button
                                  type="text"
                                  danger
                                  onClick={() => handleDelete(index)}
                                  style={styles.deleteButton}
                                >
                                  X
                                </Button>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                    <p style={styles.totalAmount}><b>Total em real: R$ {totalReal.toLocaleString(navigator.language, { maximumFractionDigits: 2 })}</b></p>
                  </div>

                  <div style={styles.section}>
                    <h2 style={styles.subTitle}>Ativos</h2>
                    {assets?.map((asset, index) => {
                      if (asset.type === "asset") {
                        return (
                          <div style={styles.lineItem} key={index}>
                            <div style={styles.inputRow}>
                              <label style={styles.labelLine}>{asset.name}: {"R$"}</label>
                              <div style={styles.inputWithDelete}>
                                <InputNumber
                                  style={styles.input}
                                  value={asset.value}
                                  onChange={(event) => handleInputChange(index, event)}
                                  step="0.01"
                                  min="0"
                                  precision={2}
                                  decimalSeparator=","
                                />
                                <Button
                                  type="text"
                                  danger
                                  onClick={() => handleDelete(index)}
                                  style={styles.deleteButton}
                                >
                                  X
                                </Button>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                    <p style={styles.totalAmount}>Total de ativos: R$ {totalAssets ? totalAssets.toFixed(2) : 0}</p>
                  </div>

                  <div style={styles.section}>
                    <h2 style={styles.subTitle}>Patrimônio líquido</h2>
                    <div style={{
                      ...styles.patrimonioContainer,
                      flexDirection: isMobile ? 'column' : 'row',
                      gap: isMobile ? '8px' : '16px',
                    }}>
                      <div style={{
                        ...styles.patrimonioColumn,
                        gap: isMobile ? '8px' : '16px',
                      }}>
                        <div style={{
                          ...styles.patrimonioItem,
                          padding: isMobile ? '12px' : '16px',
                        }}>
                          <p style={{
                            ...styles.patrimonioLabel,
                            fontSize: isMobile ? '12px' : '14px',
                          }}>Total em Real:</p>
                          <p style={{
                            ...styles.patrimonioValue,
                            fontSize: isMobile ? '16px' : '20px',
                          }}>
                            R$ {totalReal.toLocaleString(navigator.language, { maximumFractionDigits: 2 })}
                          </p>
                        </div>
                        <div style={{
                          ...styles.patrimonioItem,
                          padding: isMobile ? '12px' : '16px',
                        }}>
                          <p style={{
                            ...styles.patrimonioLabel,
                            fontSize: isMobile ? '12px' : '14px',
                          }}>Total em Ativos (BRL):</p>
                          <p style={{
                            ...styles.patrimonioValue,
                            fontSize: isMobile ? '16px' : '20px',
                          }}>
                            R$ {totalAssets.toLocaleString(navigator.language, { maximumFractionDigits: 2 })}
                          </p>
                        </div>
                        <div style={{
                          ...styles.patrimonioItem,
                          padding: isMobile ? '12px' : '16px',
                        }}>
                          <p style={{
                            ...styles.patrimonioLabel,
                            fontSize: isMobile ? '12px' : '14px',
                          }}>Patrimônio Total (BRL):</p>
                          <p style={{
                            ...styles.patrimonioValue,
                            fontSize: isMobile ? '16px' : '20px',
                          }}>
                            R$ {netWorth.toLocaleString(navigator.language, { maximumFractionDigits: 2 })}
                          </p>
                        </div>
                      </div>
                      <div style={{
                        ...styles.patrimonioColumn,
                        gap: isMobile ? '8px' : '16px',
                      }}>
                        <div style={{
                          ...styles.patrimonioItem,
                          padding: isMobile ? '12px' : '16px',
                        }}>
                          <p style={{
                            ...styles.patrimonioLabel,
                            fontSize: isMobile ? '12px' : '14px',
                          }}>Total em Dólar:</p>
                          <p style={{
                            ...styles.patrimonioValue,
                            fontSize: isMobile ? '16px' : '20px',
                          }}>
                            US$ {totalDollar.toLocaleString(navigator.language, { maximumFractionDigits: 2 })}
                          </p>
                        </div>
                        <div style={{
                          ...styles.patrimonioItem,
                          padding: isMobile ? '12px' : '16px',
                        }}>
                          <p style={{
                            ...styles.patrimonioLabel,
                            fontSize: isMobile ? '12px' : '14px',
                          }}>Total em Ativos (USD):</p>
                          <p style={{
                            ...styles.patrimonioValue,
                            fontSize: isMobile ? '16px' : '20px',
                          }}>
                            US$ {(totalAssets / usdbrl).toLocaleString(navigator.language, { maximumFractionDigits: 2 })}
                          </p>
                        </div>
                        <div style={{
                          ...styles.patrimonioItem,
                          padding: isMobile ? '12px' : '16px',
                        }}>
                          <p style={{
                            ...styles.patrimonioLabel,
                            fontSize: isMobile ? '12px' : '14px',
                          }}>Patrimônio Total (USD):</p>
                          <p style={{
                            ...styles.patrimonioValue,
                            fontSize: isMobile ? '16px' : '20px',
                          }}>
                            US$ {(netWorth / usdbrl).toLocaleString(navigator.language, { maximumFractionDigits: 2 })}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Button onClick={handleUpdate} type="primary" style={{ marginBottom: '20px' }}>
                    Atualizar
                  </Button>

                  <Form layout="vertical" style={styles.section}>
                    <h2 style={styles.subTitle}>Adicionar novo</h2>
                    <div style={styles.addNewForm}>
                      <div style={styles.formRow}>
                        <Form.Item
                          label="Nome da conta"
                          style={{ marginBottom: '0', flex: 2 }}
                        >
                          <Input
                            id="name"
                            type="text"
                            value={newAsset.name}
                            onChange={handleNameChange}
                            placeholder="Ex: Conta Corrente"
                            style={styles.formInput}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Tipo"
                          style={{ marginBottom: '0', flex: 1, marginLeft: '12px' }}
                        >
                          <Select
                            id="type"
                            value={newAsset.type}
                            onChange={handleSelectChange}
                            placeholder="Selecione o tipo"
                            style={styles.formInput}
                          >
                            <Select.Option value="BRL">Real (BRL)</Select.Option>
                            <Select.Option value="USD">Dólar (USD)</Select.Option>
                            <Select.Option value="asset">Ativo</Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label=" "
                          style={{ marginBottom: '0', marginLeft: '12px' }}
                        >
                          <Button
                            onClick={addNewAsset}
                            type="primary"
                            style={styles.addButton}
                            icon={<PlusOutlined />}
                          >
                            Adicionar
                          </Button>
                        </Form.Item>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>

              <div style={{
                width: isMobile ? '100%' : '65%',
                height: isMobile ? '400px' : 'calc(100vh - 180px)',
                padding: isMobile ? '10px 0' : '0',
                position: 'sticky',
                top: '80px',
              }}>
                <div style={{
                  ...styles.section,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                  <Line
                    data={lineData}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          position: 'top',
                          align: 'start',
                          labels: {
                            padding: 20,
                            font: {
                              size: isMobile ? 12 : 14
                            },
                            usePointStyle: true,
                            boxWidth: 6
                          }
                        },
                      },
                      scales: {
                        y: {
                          beginAtZero: true,
                          ticks: {
                            font: {
                              size: isMobile ? 12 : 14
                            }
                          }
                        },
                        x: {
                          ticks: {
                            font: {
                              size: isMobile ? 12 : 14
                            }
                          }
                        }
                      },
                    }}
                    style={{
                      height: '100%',
                      width: '100%'
                    }}
                  />
                </div>
              </div>
            </div>
          </Content>
        </div>
        <Footer style={{
          textAlign: 'center',
          padding: isMobile ? '10px' : '24px',
        }}>
          My Financial App ©2023 Created by Me
        </Footer>
      </Layout>
    </ThemeProvider>
  );
}

const styles = {
  exchangeRate: {
    fontSize: '14px',
    color: '#666',
    marginBottom: '10px',
  },
  sectionTitle: {
    fontSize: '24px',
    fontWeight: '600',
    color: '#1a1a1a',
    marginBottom: '20px',
  },
  subTitle: {
    fontSize: '20px',
    fontWeight: '500',
    color: '#333',
    marginBottom: '15px',
  },
  section: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    marginBottom: '20px',
  },
  lineItem: {
    marginBottom: '12px',
  },
  inputRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '12px',
  },
  labelLine: {
    color: '#666',
    fontSize: '14px',
    margin: 0,
    minWidth: '120px',
  },
  inputWithDelete: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    flex: 1,
  },
  input: {
    width: '100%',
  },
  deleteButton: {
    padding: '4px 8px',
    minWidth: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  totalAmount: {
    fontSize: '16px',
    color: '#1a1a1a',
    marginTop: '15px',
  },
  addNewForm: {
    width: '100%',
  },
  formRow: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
  },
  formInput: {
    borderRadius: '6px',
    fontSize: '14px',
    width: '100%',
  },
  addButton: {
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    whiteSpace: 'nowrap',
    height: '32px',
    marginTop: '0',
    borderRadius: '6px',
  },
  patrimonioContainer: {
    display: 'flex',
    gap: '16px',
    width: '100%',
  },
  patrimonioColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    flex: 1,
  },
  patrimonioItem: {
    padding: '16px',
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  patrimonioLabel: {
    fontSize: '14px',
    color: '#666',
    margin: 0,
  },
  patrimonioValue: {
    fontSize: '20px',
    fontWeight: '600',
    color: '#1a1a1a',
    margin: 0,
  },
};

export default withAuthenticator(App);
